/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {Summary} = _components;
  if (!Summary) _missingMdxReference("Summary", true);
  return React.createElement(React.Fragment, null, React.createElement(Summary, null, "Nous recherchons un(e) stagiaire en intelligence artificielle pour travailler sur un projet de consultation précis en intelligence artificielle (iA)."), "\n", React.createElement(_components.h2, null, "Rôles"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Traduire l'idée du client en un projet réaliste qui exploite des technologies de AI."), "\n", React.createElement(_components.li, null, "Développer et améliorer les modèles de AI."), "\n", React.createElement(_components.li, null, "Produire et livrer du code de qualité."), "\n", React.createElement(_components.li, null, "Documenter le travail accompli."), "\n"), "\n", React.createElement(_components.h2, null, "Un(e) bon(ne) candidat(e)"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Connaissance de Python et des libraires scientifiques populaires (numpy, pandas, scipy)."), "\n", React.createElement(_components.li, null, "Maîtrise de pytorch ou de tensorflow."), "\n", React.createElement(_components.li, null, "Bonne capacité à communiquer à l'oral et à l'écrit ses idées."), "\n"), "\n", React.createElement(_components.h2, null, "Atouts (ou ce que vous apprendrez)"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Connaissance approfondie d'un domaine particulier en AI: object detection, GNN, modèles bayesiens, ..."), "\n", React.createElement(_components.li, null, "Déployer des modèles sur AWS/GCloud/Azure."), "\n", React.createElement(_components.li, null, "Connaissance de librairies modernes de frontend (React, D3.js, ...) ou de backend (nodejs, fastapi, ...)."), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
